import axios from 'axios'
import apiAuth from './authData'

export default function contactRequest(data) {
  const { name, email, subject, message } = data
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mails`,
    {
      name,
      email,
      subject,
      message,
    },
    apiAuth
  )
}
