import { createGlobalStyle } from 'styled-components'
import SpaceGroteskWoff2Regular from './fonts/SpaceGrotesk/SpaceGrotesk-Regular.woff2'
import SpaceGroteskWoffRegular from './fonts/SpaceGrotesk/SpaceGrotesk-Regular.woff'
import SpaceGroteskWoff2Light from './fonts/SpaceGrotesk/SpaceGrotesk-Light.woff2'
import SpaceGroteskWoffLight from './fonts/SpaceGrotesk/SpaceGrotesk-Light.woff'
import HelveticaWoffLight from './fonts/Helvetica/HelveticaNeueCyr-UltraLight.woff'
import HelveticaWoff2Light from './fonts/Helvetica/HelveticaNeueCyr-UltraLight.woff2'

// Space Grotesk
const spaceGrotesk = {
  regular: {
    woff2: SpaceGroteskWoff2Regular,
    woff: SpaceGroteskWoffRegular,
  },
  light: {
    woff2: SpaceGroteskWoff2Light,
    woff: SpaceGroteskWoffLight,
  },
}

const helvetica = {
  light: {
    woff: HelveticaWoffLight,
    woff2: HelveticaWoff2Light,
  },
}

export default createGlobalStyle`
  @font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica NeueСyr'),
      url(${helvetica.light.woff2}) format('woff2'),
      url(${helvetica.light.woff}) format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'),
         url(${spaceGrotesk.regular.woff2}) format('woff2'),
         url(${spaceGrotesk.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  };
  @font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'),
         url(${spaceGrotesk.light.woff2}) format('woff2'),
         url(${spaceGrotesk.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  };
`
