import styled from 'styled-components'
import Socials from '../Socials/Socials'

export default function IndexFooter({ show = true }) {
  return (
    <IndexFooterRoot show={show}>
      <IndexFooterSocialsWrap>
        <IndexFooterSocialsText>SOCIAL MEDIA</IndexFooterSocialsText>
        <Socials />
      </IndexFooterSocialsWrap>
    </IndexFooterRoot>
  )
}

const IndexFooterRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 150px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    padding-right: 121px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    padding-right: 68px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    padding-right: 57px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    padding-right: 26px;
  }
`

const IndexFooterSocialsWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.index.sm} {
    justify-content: center;
  }
`

const IndexFooterSocialsText = styled.span`
  font-size: 16px;
  color: #ffffff;
  padding-right: 19px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    font-size: 13px;
    padding-right: 15px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    font-size: 11px;
    padding-right: 13px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    font-size: 16px;
    padding-right: 19px;
  }
  ${({ theme }) => theme.adaptive.newIndex.xsm} {
    font-size: 13px;
    padding-right: 16px;
  }
`
