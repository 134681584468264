import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  #root {
    min-height: 100%;
    /* height: 100%; */
  }
  html {
    min-height: 100%;
    height: 100%;
  }
  body {
    font-family: 'Space Grotesk';
    margin: 0;
    background-color: #000000;
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
  a {
    text-decoration: none;
  }
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 400;
  }
  button {
    cursor: pointer;
    border: 0;
    padding: 0;
    font-family: 'Space Grotesk';
  }
  textarea, input {
    font-family: 'Space Grotesk';
  }
`
