import { BrowserRouter as RouterReact, Route, Switch } from 'react-router-dom'
import Index from '../components/Index/Index'
import Roadmap from '../components/Roadmap/Roadmap'
import Team from '../components/Team/Team'

export default function Router() {
  return (
    <RouterReact>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/team">
          <Team />
        </Route>
        <Route path="/roadmap">
          <Roadmap />
        </Route>
        {/* <Route path="/liquidity-mining">
          <LiquidityMining />
        </Route> */}
        {/*<Route path="/mvp">*/}
        {/*  <Swap />*/}
        {/*</Route>*/}
      </Switch>
    </RouterReact>
  )
}
