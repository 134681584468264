import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import IndexNavigation from './IndexNavigation'
import IndexTitle from './IndexTitle'
import IndexFooter from './IndexFooter'
import indexBackgroundPath from '../../img/bg.jpg'
import indexBackgroundPath2x from '../../img/bg-2x.jpg'
import indexBackgroundPathWebp from '../../img/bg@1x.webp'
import indexBackgroundPath2xWebp from '../../img/bg@2x.webp'
import { contactPopupStore } from '../../store/contact'

export default function IndexContent() {
  const isContactOpen = useStore(contactPopupStore)

  return (
    <IndexContentRoot>
      <IndexContentWrapper>
        <IndexNavigation show={!isContactOpen} />
        <IndexTitle show={!isContactOpen} />
        <IndexFooter show={!isContactOpen} />
      </IndexContentWrapper>
    </IndexContentRoot>
  )
}

const IndexContentRoot = styled.div`
  height: 100%;
  padding: 4px 4px 0 4px;
  background: #191919;
`

const IndexContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 1px solid #212122;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  background: url(${indexBackgroundPath}) no-repeat center;
  background-image: url(${indexBackgroundPath});
  background-image: image-set(
    url(${indexBackgroundPathWebp}) 1x,
    url(${indexBackgroundPath2xWebp}) 2x,
    url(${indexBackgroundPath}) 1x,
    url(${indexBackgroundPath2x}) 2x
  );
  padding-bottom: 92px;
  padding-left: 150px;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    padding-bottom: 74px;
    padding-left: 121px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    padding-bottom: 78px;
    padding-left: 68px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    padding-left: 57px;
    padding-bottom: 67px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    padding-left: 26px;
    padding-bottom: 24px;
  }
`
