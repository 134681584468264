import React, { useState } from 'react'
import styled from 'styled-components'
import PageWrapper from '../PageWrapper/PageWrapper'
import LinkedinIcon from '../../icons/Linkedin'
import teamStore from '../../store/team'

export default function Team() {
  const [teamTab, setTeamTab] = useState(0)

  return (
    <PageWrapper title="DeFIRE Team">
      <TeamContentWrapper>
        <TeamContainer>
          <TeamRoles>
            {teamStore.map((team, idxTeam) => {
              return (
                <TeamRolesItem
                  active={teamTab === idxTeam}
                  key={idxTeam}
                  onClick={() => setTeamTab(idxTeam)}
                  show={teamTab === idxTeam}
                >
                  {team.name}
                </TeamRolesItem>
              )
            })}
          </TeamRoles>
          <TeamContent>
            <TeamContentBorder>
              {teamStore.map((team, idxTeam) => {
                return (
                  <TeamList show={teamTab === idxTeam}>
                    {team.people.map((employee, idx) => {
                      return (
                        <TeamItem
                          href={employee.link}
                          target="_blank"
                          key={idx}
                        >
                          <TeamItemPhoto backroundImagePath={employee.photo} />
                          <TeamItemName>{employee.name}</TeamItemName>
                          <TeamItemDesc>{employee.desc}</TeamItemDesc>
                          {employee.link && (
                            <TeamLinkedinWrapper>
                              <LinkedinIcon />
                            </TeamLinkedinWrapper>
                          )}
                        </TeamItem>
                      )
                    })}
                  </TeamList>
                )
              })}
            </TeamContentBorder>
          </TeamContent>
        </TeamContainer>
      </TeamContentWrapper>
    </PageWrapper>
  )
}

const TeamContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TeamContent = styled.div`
  background: #1f1e1f;
  padding: 6px;
`

const TeamContainer = styled.div`
  width: 1540px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.team.lg} {
    width: 1270px;
  }
  ${({ theme }) => theme.adaptive.team.md} {
    width: 920px;
  }
  ${({ theme }) => theme.adaptive.team.smd} {
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.team.sm} {
    width: 372px;
  }
  ${({ theme }) => theme.adaptive.team.xsm} {
    width: 100%;
  }
`

const TeamRoles = styled.div`
  display: flex;
  width: 100%;
  padding: 0 5px;
`

const TeamContentBorder = styled.div`
  border: 1px solid #4c2025;
`

const TeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
  display: ${({ show }) => (show ? 'flex' : 'none')} !important;
  ${({ theme }) => theme.adaptive.team.lg} {
    padding: 30px;
  }
  ${({ theme }) => theme.adaptive.team.sm} {
    display: block;
    padding: 0;
  }
`

const TeamRolesItem = styled.div`
  position: relative;
  font-size: 16px;
  color: #bbbbbb;
  text-align: center;
  background: #121212;
  border-radius: 5px 5px 0 0;
  width: 292px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ show }) => (show ? 'default' : 'pointer')};
  margin-left: 15px;
  ${({ theme }) => theme.adaptive.team.sm} {
    font-size: 14px;
    width: 200px;
    padding: 0 12px;
  }
  &:first-child {
    margin-left: 0;
  }
  &:before,
  &:after {
    display: ${({ show }) => (show ? 'block' : 'none')};
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    left: 8px;
  }
  &:before {
    bottom: -7px;
    width: calc(100% - 16px);
    height: 10px;
    background: #1f1e1f;
    border-left: 1px solid #4c2025;
    border-right: 1px solid #4c2025;
  }
  &:after {
    height: 100%;
    border: 1px solid #4c2025;
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    top: 5px;
  }
  ${({ active }) =>
    active &&
    `
    background: #1F1E1F;
    color: #FFFFFF;
  `}
`

const TeamLinkedinWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 55px;
  opacity: 0.2;
  ${({ theme }) => theme.adaptive.team.lg} {
    width: 47px;
    top: 22px;
  }
  ${({ theme }) => theme.adaptive.team.md} {
    width: 40px;
    top: 11px;
  }
`

const TeamItemPhoto = styled.div`
  width: 104px;
  height: 104px;
  background: url(${({ backroundImagePath }) => backroundImagePath}) no-repeat
    center;
  background-size: cover;
  margin-bottom: 14px;
  filter: grayscale(100%);
  ${({ theme }) => theme.adaptive.team.lg} {
    width: 90px;
    height: 90px;
  }
  ${({ theme }) => theme.adaptive.team.md} {
    width: 55px;
    height: 55px;
  }
`

const TeamItem = styled.a`
  display: block;
  position: relative;
  width: 278px;
  height: 234px;
  padding: 30px;
  border: 1px solid #1f1e1f;
  ${({ theme }) => theme.adaptive.team.lg} {
    width: 237px;
    height: 207px;
    padding: 22px;
  }
  ${({ theme }) => theme.adaptive.team.md} {
    width: 193px;
    height: 160px;
    padding: 11px;
  }
  ${({ theme }) => theme.adaptive.team.smd} {
    width: 33.3333%;
  }
  ${({ theme }) => theme.adaptive.team.sm} {
    width: 100%;
  }
  &:hover {
    background: #121212;
    border: 1px solid #2c2b2d;
    ${TeamLinkedinWrapper} {
      opacity: 1;
    }
    ${TeamItemPhoto} {
      filter: grayscale(0%);
    }
  }
`

const TeamItemName = styled.div`
  font-size: 21px;
  color: #fff;
  margin-bottom: 12px;
  ${({ theme }) => theme.adaptive.team.lg} {
    font-size: 17px;
  }
`

const TeamItemDesc = styled.p`
  font-size: 16px;
  line-height: 18px;
  color: #706f71;
  ${({ theme }) => theme.adaptive.team.lg} {
    font-size: 15px;
  }
`
