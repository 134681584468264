import React from 'react'

export default function Linkedin() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 55 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.0498H2.13646V9.83195H6.09167V11.8036H0V2.0498Z"
        fill="#FE273B"
      />
      <path
        d="M7.0362 5.19009H9.08864V11.7998H7.0362V5.19009ZM8.06242 1.90405C8.2979 1.9041 8.52807 1.97396 8.72384 2.10481C8.91961 2.23567 9.07218 2.42163 9.16226 2.63919C9.25234 2.85676 9.27589 3.09615 9.22993 3.32709C9.18396 3.55804 9.07055 3.77016 8.90403 3.93665C8.73751 4.10315 8.52536 4.21652 8.29441 4.26244C8.06346 4.30837 7.82407 4.28478 7.60652 4.19465C7.38898 4.10453 7.20304 3.95193 7.07222 3.75613C6.94141 3.56034 6.87158 3.33015 6.87158 3.09468C6.87158 2.93831 6.90239 2.78346 6.96223 2.639C7.02208 2.49453 7.1098 2.36327 7.22038 2.2527C7.33097 2.14214 7.46225 2.05444 7.60673 1.99462C7.7512 1.9348 7.90605 1.90402 8.06242 1.90405Z"
        fill="#FE273B"
      />
      <path
        d="M17.6742 2.04834H19.7267V7.87747L22.0527 5.18728H24.5691L21.8753 8.24734L24.5125 11.8027H21.9328L19.754 8.53553H19.7271V11.8023H17.6746L17.6742 2.04834Z"
        fill="#FE273B"
      />
      <path
        d="M10.2267 5.19085H12.1976V6.09413H12.2256C12.4225 5.75684 12.7073 5.47936 13.0496 5.29132C13.3919 5.10327 13.7788 5.01171 14.169 5.02643C16.2503 5.02643 16.6335 6.3958 16.6335 8.17479V11.7995H14.581V8.58591C14.581 7.81882 14.5669 6.83279 13.512 6.83279C12.4452 6.83279 12.2806 7.66809 12.2806 8.53011V11.7986H10.2281L10.2267 5.19085Z"
        fill="#FE273B"
      />
      <path
        d="M28.8914 7.72633C28.8954 7.55981 28.8654 7.39424 28.8035 7.23963C28.7416 7.08502 28.6489 6.94459 28.531 6.82685C28.4132 6.70912 28.2727 6.61652 28.1181 6.55468C27.9634 6.49284 27.7978 6.46303 27.6313 6.46707C27.2727 6.44476 26.9197 6.56427 26.6484 6.79983C26.3771 7.03539 26.2092 7.36813 26.1809 7.72633H28.8914ZM30.63 10.6553C30.289 11.0689 29.8602 11.4015 29.3748 11.6289C28.8893 11.8564 28.3594 11.973 27.8233 11.9704C25.7709 11.9704 24.1277 10.6004 24.1277 8.47889C24.1277 6.3574 25.7707 4.98804 27.8233 4.98804C29.7417 4.98804 30.9447 6.35633 30.9447 8.47889V9.12241H26.1809C26.2403 9.48737 26.4295 9.81861 26.7137 10.0551C26.998 10.2916 27.3581 10.4173 27.7278 10.4092C28.0199 10.4074 28.3069 10.3326 28.5627 10.1915C28.8186 10.0504 29.035 9.84763 29.1925 9.60153L30.63 10.6553Z"
        fill="#FE273B"
      />
      <path
        d="M35.2228 6.78788C34.1965 6.78788 33.5808 7.47309 33.5808 8.47151C33.5808 9.46994 34.1963 10.1558 35.2228 10.1558C36.2492 10.1558 36.8662 9.47165 36.8662 8.47151C36.8662 7.47138 36.2503 6.78788 35.2228 6.78788ZM38.7557 11.7986H36.8662V10.922H36.8382C36.6012 11.2409 36.2937 11.5007 35.9398 11.6812C35.5858 11.8617 35.195 11.9581 34.7977 11.9628C32.8257 11.9628 31.5267 10.5396 31.5267 8.52689C31.5267 6.67841 32.6762 4.98109 34.566 4.98109C35.4154 4.98109 36.2088 5.21349 36.6742 5.85765H36.7011V2.04761H38.7555L38.7557 11.7986Z"
        fill="#FE273B"
      />
      <path
        d="M52.6116 11.8024H50.5592V8.58825C50.5592 7.82179 50.5455 6.83512 49.4917 6.83512C48.4227 6.83512 48.2592 7.67021 48.2592 8.53244V11.8022H46.2067V5.19253H48.1771V6.09582H48.2047C48.4018 5.75867 48.6868 5.48132 49.0291 5.2933C49.3715 5.10528 49.7584 5.01364 50.1487 5.02813C52.2289 5.02813 52.6125 6.39642 52.6125 8.17647L52.6116 11.8024ZM43.8909 4.28904C43.6553 4.28908 43.425 4.21927 43.2292 4.08843C43.0333 3.95759 42.8806 3.7716 42.7904 3.55398C42.7002 3.33636 42.6766 3.09688 42.7225 2.86583C42.7684 2.63478 42.8818 2.42253 43.0483 2.25593C43.2149 2.08933 43.4271 1.97585 43.6581 1.92986C43.8892 1.88386 44.1286 1.9074 44.3463 1.99751C44.5639 2.08762 44.75 2.24025 44.8809 2.43609C45.0118 2.63193 45.0817 2.8622 45.0817 3.09777C45.0818 3.25418 45.051 3.40906 44.9912 3.55358C44.9313 3.6981 44.8436 3.82941 44.7331 3.94003C44.6225 4.05065 44.4912 4.13841 44.3467 4.19829C44.2022 4.25817 44.0473 4.28901 43.8909 4.28904ZM44.9171 11.8024H42.8626V5.19253H44.9171V11.8024ZM53.6348 0.000943728H41.8312C41.5633 -0.00207963 41.3051 0.101384 41.1135 0.288598C40.9218 0.475813 40.8123 0.731461 40.8091 0.999367V12.852C40.8122 13.12 40.9216 13.3758 41.1133 13.5632C41.3049 13.7506 41.5632 13.8543 41.8312 13.8515H53.6348C53.9034 13.8548 54.1624 13.7515 54.3548 13.5641C54.5472 13.3766 54.6573 13.1205 54.6611 12.852V0.998513C54.6572 0.730075 54.547 0.474135 54.3546 0.286925C54.1621 0.0997155 53.9033 -0.00345217 53.6348 8.82007e-05"
        fill="#FE273B"
      />
    </svg>
  )
}
