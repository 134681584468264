import palette from './palette'

const theme = {
  mobileWidth: 900,
  adaptive: {
    contact: {
      lg: '@media screen and (max-width: 1240px)',
      md: '@media screen and (max-width: 1060px)',
      sm: '@media screen and (max-width: 640px)',
      smm: '@media screen and (max-width: 400px)',
    },
    index: {
      lg: '@media screen and (max-width: 1240px)',
      md: '@media screen and (max-width: 959px)',
      sm: '@media screen and (max-width: 695px)',
      xsm: '@media screen and (max-width: 400px)',
    },
    team: {
      lg: '@media screen and (max-width: 1570px)',
      md: '@media screen and (max-width: 1300px)',
      smd: '@media screen and (max-width: 960px)',
      sm: '@media screen and (max-width: 760px)',
      xsm: '@media screen and (max-width: 410px)',
    },
    roadmap: {
      lg: '@media screen and (max-width: 1700px)',
      md: '@media screen and (max-width: 1300px)',
      md2: '@media screen and (max-width: 1100px)',
      smd: '@media screen and (max-width: 840px)',
      sm: '@media screen and (max-width: 700px)',
      smm: '@media screen and (max-width: 400px)',
    },
    swap: {
      lg: '@media screen and (max-width: 1800px)',
      md: '@media screen and (max-width: 1200px)',
      sm: '@media screen and (max-width: 900px)',
      xs: '@media screen and (max-width: 390px), screen and (max-height: 680px)',
    },
    liquidityMining: {
      lg: '@media screen and (max-width: 1920px)',
      md: '@media screen and (max-width: 1440px)',
      sm: '@media screen and (max-width: 1200px)',
      xs: '@media screen and (max-width: 900px)',
    },
    plug: {
      lg: '@media screen and (max-width: 1240px), screen and (max-height: 800px)',
    },
    newIndex: {
      xl: '@media screen and (max-width: 1440px)',
      lg: '@media screen and (max-width: 1240px)',
      md: '@media screen and (max-width: 959px)',
      sm: '@media screen and (max-width: 695px)',
      xsm: '@media screen and (max-width: 400px)',
    },
  },
  palette,
}

export default theme
