import React from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import Header from '../Header/Header'
import IndexContent from './IndexContent'
import Contact from '../Contact/Contact'
import { contactPopupStore, closeContact } from '../../store/contact'

export default function Index() {
  const isContactOpen = useStore(contactPopupStore)

  return (
    <IndexRoot>
      <IndexWrapper>
        <Header />
        <IndexContent />
      </IndexWrapper>
      <Contact open={isContactOpen} onContactClose={closeContact} />
    </IndexRoot>
  )
}

const IndexRoot = styled.div`
  padding: 5px 5px 0 5px;
  height: 100vh;
  overflow: hidden;
  position: relative;
`

const IndexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #212122;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  background: #161616;
`
