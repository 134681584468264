import React, { useState } from 'react'
import styled from 'styled-components'
import HeaderTemplate from './HeaderTemplate'
import HeaderDocs from './HeaderDocs'
import folderOpenIconPath from '../../img/folder-open.svg'
import folderCloseIconPath from '../../img/folder-close.svg'
import arrowImg from '../../img/arrow-dapp.svg'

export default function Header() {
  const [docsOpen, setDocsOpen] = useState(false)

  return (
    <HeaderTemplate>
      <Row>
        <HeaderButton open={docsOpen} onClick={() => setDocsOpen(!docsOpen)}>
          DOCS
        </HeaderButton>
        <DApp href={'http://app.defire.fi/'} target={'_blank'}>
          Launch dApp
        </DApp>
      </Row>
      <HeaderDocsWrapper>
        <HeaderDocs open={docsOpen} />
      </HeaderDocsWrapper>
    </HeaderTemplate>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
`

const HeaderButton = styled.button`
  position: relative;
  font-size: 16px;
  color: #ffffff;
  border-radius: 2px;
  background: #1c1c1d;
  padding: 9px 20px;
  width: 154px;
  height: 38px;
  text-align: left;
  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 19px;
    height: 12px;
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 16px;
      height: 10px;
      right: 10px;
    }
  }
  &:before {
    background: url(${folderOpenIconPath}) no-repeat center;
    background-size: contain;
    opacity: ${({ open }) => (open ? 1 : 0)};
  }
  &:after {
    background: url(${folderCloseIconPath}) no-repeat center;
    background-size: contain;
    opacity: ${({ open }) => (open ? 0 : 1)};
  }
`

const HeaderDocsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 2;
`

const DApp = styled.a`
  position: relative;
  display: block;
  width: 162px;
  height: 38px;
  margin-left: 14px;
  padding: 9px 19px;
  text-align: left;
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  background: #fe273b;
  border-radius: 2px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.index.xsm} {
    padding: 6px 12px;
    width: 132px;
    height: 28px;
    font-size: 11px;
    line-height: 14px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 14px;
    right: 16px;
    width: 6px;
    height: 9px;
    background: url('${arrowImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.index.xsm} {
      top: 10px;
      right: 11px;
      width: 4px;
      height: 7px;
    }
  }
`
