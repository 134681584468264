import styled from 'styled-components'
import cardano from '../../img/roadmap/cardano.svg'
import etherium from '../../img/roadmap/etherium.svg'
import stageOneImg from '../../img/roadmap/stage-one.svg'
import stageThreeImg from '../../img/roadmap/stage-three.svg'
import stageTwoImg from '../../img/roadmap/stage-two.svg'
import PageWrapper from '../PageWrapper/PageWrapper'

export default function Roadmap() {
  return (
    <PageWrapper color={'#262627'} title="DeFIRE Roadmap">
      <RoadmapContentWrapper>
        <RoadmapContainer>
          <LeftColumn>
            <LeftBlockchain>Blockchain Development</LeftBlockchain>
            <LeftFeatures>Features Development</LeftFeatures>
          </LeftColumn>
          <RoadmapItem>
            <RoadmapItemHead>
              <RoadmapImageWrap>
                <RoadmapImage src={stageOneImg} />
              </RoadmapImageWrap>
              <RoadmapTextCont>
                <RoadmapTextGray>Stage I</RoadmapTextGray>
                <RoadmapTextColor txtColor={'#862BC0'}>
                  Minimum Viable Product
                </RoadmapTextColor>
              </RoadmapTextCont>
            </RoadmapItemHead>
            <RoadmapLines>
              <RoadmapLine backgroundClr={'#862BC0'} />
              <RoadmapLine />
              <RoadmapLine />
            </RoadmapLines>
            <MobileBlockchain>Blockchain Development:</MobileBlockchain>
            <RoadmapCrypto>
              <Etherium>Ethereum</Etherium>
              <Cardano>Cardano (Testnet Research)</Cardano>
            </RoadmapCrypto>
            <RoadmapDelimiter />
            <MobileFeatures>Features Development:</MobileFeatures>
            <RoadmapList>
              <RoadmapListItem discColor={'#862BC0'}>
                Limit Orders
              </RoadmapListItem>
              <RoadmapListItem discColor={'#862BC0'}>
                Order Routing
              </RoadmapListItem>
              <RoadmapListItem discColor={'#862BC0'}>
                0x, Curve, Uniswap, Sushiswap, Bancor
              </RoadmapListItem>
              <RoadmapListItem discColor={'#862BC0'}>
                Lit Orderbooks
              </RoadmapListItem>
              <RoadmapListItem discColor={'#862BC0'}>cWAP</RoadmapListItem>
            </RoadmapList>
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapItemHead>
              <RoadmapImageWrap>
                <RoadmapImage src={stageTwoImg} />
              </RoadmapImageWrap>
              <RoadmapTextCont>
                <RoadmapTextGray>Stage II</RoadmapTextGray>
                <RoadmapTextColor txtColor={'#E7AA01'}>
                  Extensions & Cardano Tests
                </RoadmapTextColor>
              </RoadmapTextCont>
            </RoadmapItemHead>
            <RoadmapLines>
              <RoadmapLine />
              <RoadmapLine backgroundClr={'#E7AA01'} />
              <RoadmapLine />
            </RoadmapLines>
            <MobileBlockchain>Blockchain Development:</MobileBlockchain>
            <RoadmapCrypto>
              <Etherium>Ethereum</Etherium>
              <Cardano>Cardano (Testnet Deploy)</Cardano>
            </RoadmapCrypto>
            <RoadmapDelimiter />
            <MobileFeatures>Features Development:</MobileFeatures>
            <RoadmapList>
              <RoadmapListItem discColor={'#E7AA01'}>
                Fill-or-Kill
              </RoadmapListItem>
              <RoadmapListItem discColor={'#E7AA01'}>
                Dark Orderbooks
              </RoadmapListItem>
              <RoadmapListItem discColor={'#E7AA01'}>
                Front-Running Prevention
              </RoadmapListItem>
              <RoadmapListItem discColor={'#E7AA01'}>
                Guaranteed Prioritization
              </RoadmapListItem>
              <RoadmapListItem discColor={'#E7AA01'}>
                Extended Number of DEXs
              </RoadmapListItem>
              <RoadmapListItem discColor={'#E7AA01'}>
                Order Execution Quality Optimizer
              </RoadmapListItem>
              <RoadmapListItem discColor={'#E7AA01'}>
                MVP on Cardano Testnet
              </RoadmapListItem>
            </RoadmapList>
          </RoadmapItem>
          <RoadmapItem>
            <RoadmapItemHead>
              <RoadmapImageWrap>
                <RoadmapImage src={stageThreeImg} />
              </RoadmapImageWrap>
              <RoadmapTextCont>
                <RoadmapTextGray>Stage III</RoadmapTextGray>
                <RoadmapTextColor txtColor={'#FE273B'}>
                  Deploy Mainnet Plutus
                </RoadmapTextColor>
              </RoadmapTextCont>
            </RoadmapItemHead>
            <RoadmapLines>
              <RoadmapLine />
              <RoadmapLine />
              <RoadmapLine backgroundClr={'#FE273B'} />
            </RoadmapLines>
            <MobileBlockchain>Blockchain Development:</MobileBlockchain>
            <RoadmapCrypto>
              <Etherium>Ethereum</Etherium>
              <Cardano>Cardano (Mainnet Research)</Cardano>
            </RoadmapCrypto>
            <RoadmapDelimiter />
            <MobileFeatures>Features Development:</MobileFeatures>
            <RoadmapList>
              <RoadmapListItem discColor={'#FE273B'}>
                MVP on Cardano Mainnet
              </RoadmapListItem>
              <RoadmapListItem discColor={'#FE273B'}>
                Extended Features on Cardano Testnet & Mainnet
              </RoadmapListItem>
            </RoadmapList>
          </RoadmapItem>
        </RoadmapContainer>
      </RoadmapContentWrapper>
    </PageWrapper>
  )
}

const RoadmapContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const RoadmapContainer = styled.div`
  width: 1623px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    width: 1238px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    width: 1024px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    width: 760px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    width: 584px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    width: 371px;
    padding: 11px 11px 11px 50px;
    flex-wrap: wrap;
  }
  ${({ theme }) => theme.adaptive.roadmap.smm} {
    width: 300px;
    padding: 11px 11px 11px 54px;
  }
`

const RoadmapItemHead = styled.div`
  display: flex;
  align-items: center;
  margin-right: 23px;
  padding: 31px;
  border: 1px solid #383838;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-right: 18px;
    padding: 27px 24px 17px 24px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-right: 16px;
    padding: 18px 16px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-right: 12px;
    padding: 16px 10px 15px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    align-items: flex-start;
    margin-right: 8px;
    padding: 13px 9px 6px 9px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    align-items: center;
    margin-right: 0;
    padding: 25px 21px 25px 25px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    margin-right: 0;
    padding: 20px 16px;
  }
`

const RoadmapItem = styled.div`
  width: 471px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    width: 347px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    width: 295px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    width: 220px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    width: 159px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    width: 310px;
    position: relative;
  }
  ${({ theme }) => theme.adaptive.roadmap.smm} {
    width: 238px;
    position: relative;
  }
  &:last-child {
    width: 448px;
    ${({ theme }) => theme.adaptive.roadmap.lg} {
      width: 329px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md} {
      width: 280px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md2} {
      width: 208px;
    }
    ${({ theme }) => theme.adaptive.roadmap.smd} {
      width: 151px;
    }
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      width: 310px;
    }
    ${({ theme }) => theme.adaptive.roadmap.smm} {
      width: 238px;
    }
  }
  &:last-child ${RoadmapItemHead} {
    margin-right: 0;
  }
`

const RoadmapImageWrap = styled.div`
  height: 100px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    height: 74px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    height: 64px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    height: 48px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    height: 70px;
  }
`

const RoadmapImage = styled.img`
  width: 100%;
  height: 100%;
`

const RoadmapTextCont = styled.div`
  margin-left: 31px;
  max-width: 250px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-left: 16px;
    max-width: 200px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-left: 10px;
    max-width: 200px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-left: 7px;
    max-width: 132px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    margin-left: 5px;
    max-width: 85px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    margin-left: 24px;
    max-width: 200px;
  }
`

const RoadmapTextGray = styled.div`
  font-size: 21px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.35;
  margin-bottom: 10px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    font-size: 18px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    font-size: 16px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    font-size: 11px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smm} {
    font-size: 14px;
    line-height: 16px;
  }
`

const RoadmapTextColor = styled.div`
  font-size: 28px;
  line-height: 32px;
  color: ${({ txtColor }) => (txtColor ? txtColor : 'white')};
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    font-size: 21px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    font-size: 17px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    font-size: 13px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    font-size: 20px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smm} {
    font-size: 12px;
    line-height: 15px;
  }
`

const RoadmapLines = styled.div`
  width: 100%;
  padding-top: 28px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    padding-top: 34px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    padding-top: 28px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    padding-top: 21px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    position: absolute;
    left: -43px;
    top: 0;
    height: 100%;
    width: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
`

const RoadmapLine = styled.div`
  margin-bottom: 5px;
  height: 9px;
  background: ${({ backgroundClr }) =>
    backgroundClr ? backgroundClr : 'rgba(255, 255, 255, 0.04)'};
  position: relative;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    height: 7px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    height: 6px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    height: 5px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    width: 6px;
    height: 100%;
    margin: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:nth-child(1) {
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      order: 3;
    }
    &:before {
      height: 50px;
      ${({ theme }) => theme.adaptive.roadmap.md2} {
        height: 36px;
      }
      ${({ theme }) => theme.adaptive.roadmap.sm} {
        width: 36px;
        height: 2px;
      }
    }
  }
  &:nth-child(2) {
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      order: 2;
    }
    &:before {
      height: 59px;
      ${({ theme }) => theme.adaptive.roadmap.md2} {
        height: 42px;
      }
      ${({ theme }) => theme.adaptive.roadmap.sm} {
        width: 46px;
        height: 2px;
      }
    }
  }
  &:nth-child(3) {
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      order: 1;
    }
    &:before {
      height: 68px;
      ${({ theme }) => theme.adaptive.roadmap.md2} {
        height: 49px;
      }
      ${({ theme }) => theme.adaptive.roadmap.sm} {
        width: 54px;
        height: 2px;
      }
    }
  }
  &:before {
    content: ${({ backgroundClr }) => (backgroundClr ? '' : 'unset')};
    position: absolute;
    width: 2px;
    bottom: 1px;
    left: 14px;
    background: ${({ backgroundClr }) =>
      backgroundClr ? backgroundClr : 'transparent'};
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      height: 2px;
      left: 0;
      top: 14px;
    }
  }
`

const RoadmapCrypto = styled.div`
  margin-top: 62px;
  font-size: 21px;
  line-height: 19px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-top: 34px;
    font-size: 18px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-top: 36px;
    font-size: 15px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-top: 25px;
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    margin-top: 18px;
    font-size: 16px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smm} {
    font-size: 13px;
  }
`

const Etherium = styled.div`
  margin-bottom: 19px;
  position: relative;
  margin-left: 30px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-left: 25px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-left: 20px;
  }
  &:before {
    content: '';
    position: absolute;
    background: url(${etherium}) center no-repeat;
    left: -30px;
    top: -2px;
    width: 18px;
    height: 22px;
    ${({ theme }) => theme.adaptive.roadmap.lg} {
      width: 17px;
      height: 16px;
      background-size: contain;
      top: 1px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md} {
      left: -25px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md2} {
      width: 13px;
      height: 13px;
      left: -21px;
      top: 0;
    }
    ${({ theme }) => theme.adaptive.roadmap.smd} {
      left: -20px;
    }
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      width: 13px;
      height: 15px;
      top: -2px;
    }
  }
`

const Cardano = styled.div`
  position: relative;
  margin-left: 30px;
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-left: 25px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-left: 20px;
  }
  &:before {
    content: '';
    position: absolute;
    background: url(${cardano}) center no-repeat;
    left: -30px;
    top: 0;
    width: 18px;
    height: 17px;
    ${({ theme }) => theme.adaptive.roadmap.lg} {
      width: 17px;
      height: 16px;
      top: 2px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md} {
      left: -25px;
      top: 1px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md2} {
      width: 13px;
      height: 12px;
      background-size: contain;
      left: -20px;
    }
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      width: 13px;
      height: 15px;
      top: -2px;
    }
  }
`

const RoadmapDelimiter = styled.div`
  height: 1px;
  width: 100%;
  background: #ffffff;
  opacity: 0.1;
  margin-top: 50px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-top: 33px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-top: 13px;
    height: 2px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    margin-top: 30px;
    height: 1px;
  }
`

const RoadmapList = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-left: 31px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-top: 33px;
    margin-left: 16px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-top: 53px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-top: 39px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    margin-top: 11px;
    margin-bottom: 20px;
  }
`

const RoadmapListItem = styled.p`
  position: relative;
  max-width: 350px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    font-size: 16px;
    line-height: 19px;
    max-width: 250px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    font-size: 14px;
    line-height: 19px;
    max-width: 240px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 10px;
    max-width: 180px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    max-width: 155px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    font-size: 14px;
    line-height: 16px;
    max-width: 260px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smm} {
    font-size: 13px;
    max-width: 210px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: '\\A';
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background: ${({ discColor }) => (discColor ? discColor : 'white')};
    position: absolute;
    top: 8px;
    left: -20px;
    ${({ theme }) => theme.adaptive.roadmap.lg} {
      left: -11px;
    }
    ${({ theme }) => theme.adaptive.roadmap.md2} {
      width: 3px;
      height: 3px;
      top: 6px;
    }
    ${({ theme }) => theme.adaptive.roadmap.sm} {
      left: -14px;
      top: 7px;
    }
  }
`

const LeftColumn = styled.div`
  width: 166px;
  margin-right: 65px;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-right: 54px;
    width: 132px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-right: 36px;
    width: 112px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-right: 21px;
    width: 90px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    margin-right: 24px;
    width: 90px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    display: none;
  }
`

const LeftBlockchain = styled.div`
  margin-top: 286px;
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-top: 220px;
    font-size: 18px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-top: 192px;
    font-size: 17px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-top: 147px;
    font-size: 13px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    margin-top: 145px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    display: none;
  }
`

const LeftFeatures = styled.div`
  margin-top: 81px;
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.roadmap.lg} {
    margin-top: 60px;
    font-size: 18px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md} {
    margin-top: 60px;
    font-size: 17px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.roadmap.md2} {
    margin-top: 40px;
    font-size: 13px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.roadmap.smd} {
    margin-top: 50px;
    font-size: 13px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    display: none;
  }
`

const MobileBlockchain = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    display: block;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    opacity: 0.5;
    margin-top: 29px;
  }
`

const MobileFeatures = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.roadmap.sm} {
    display: block;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    opacity: 0.5;
    margin-top: 29px;
  }
`
