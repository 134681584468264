import styled, { keyframes } from 'styled-components'
import { openContact } from '../../store/contact'
import { Link } from 'react-router-dom'
import useWindowSize from 'helpers/utils/useWindowSize'
import folderOpenIconPath from '../../img/folder-open.svg'
import folderCloseIconPath from '../../img/folder-close.svg'
import HeaderDocs from '../Header/HeaderDocs'
import React, { useState } from 'react'
import { faqLink, lmLink } from '../../configs/app'

export default function IndexNavigation({ show = true }) {
  const [docsOpen, setDocsOpen] = useState(false)
  const { width } = useWindowSize()

  return (
    <IndexNavigationRoot show={show}>
      <IndexNavigationList>
        <IndexNavigationItemWrapper>
          <Link to="/team">Team</Link>
        </IndexNavigationItemWrapper>
        {/*<IndexNavigationItemWrapper>*/}
        {/*  <Link to="/mvp">MVP</Link>*/}
        {/*</IndexNavigationItemWrapper>*/}
        <IndexNavigationItemWrapper>
          <a href={lmLink} target="_blank" rel="noreferrer">
            {width < 900 ? 'LM' : 'Liquidity Mining'}
          </a>
        </IndexNavigationItemWrapper>
        <IndexNavigationItemWrapper>
          <Link to="/roadmap">Roadmap</Link>
        </IndexNavigationItemWrapper>
        <IndexNavigationItemWrapper>
          <a href={faqLink} target="_blank" rel="noreferrer">
            FAQ
          </a>
        </IndexNavigationItemWrapper>
        <IndexNavigationItemWrapper>
          <button onClick={openContact}>Contact Us</button>
        </IndexNavigationItemWrapper>
        {/*<IndexNavigationItemWrapper>*/}
        {/*  <IndexNavigationItemActive*/}
        {/*    href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xE74dC43867E0cbEB208F1a012fc60DcBbF0E3044&use=V2"*/}
        {/*    target="_blank"*/}
        {/*  >*/}
        {/*    <IndexNavigationItemActiveCircle />*/}
        {/*    Buy CWAP*/}
        {/*  </IndexNavigationItemActive>*/}
        {/*</IndexNavigationItemWrapper>*/}
        {width <= 695 && (
          <IndexNavigationItemWrapper>
            <DocsRow>
              <Docs open={docsOpen} onClick={() => setDocsOpen(!docsOpen)}>
                DOCS
              </Docs>
            </DocsRow>
          </IndexNavigationItemWrapper>
        )}
        {width <= 695 && (
          <HeaderDocsWrapper>
            <HeaderDocs open={docsOpen} />
          </HeaderDocsWrapper>
        )}
      </IndexNavigationList>
    </IndexNavigationRoot>
  )
}

const IndexNavigationRoot = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 35px 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-bottom: 0;
  }
`

const IndexNavigationList = styled.nav`
  position: relative;
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-left: 0;
  }
`

const IndexNavigationItemWrapper = styled.span`
  padding-right: 56px;
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-right: 38px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    display: inline-block;
    padding-right: 22px;
    padding-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.index.xsm} {
    padding-bottom: 15px;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    ${({ theme }) => theme.adaptive.index.sm} {
      padding-bottom: 0;
    }
    a,
    button {
      &:before {
        display: none;
      }
    }
  }
  button {
    background: none;
  }
  a,
  button {
    position: relative;
    font-size: 16px;
    color: #fff;
    white-space: nowrap;
    &.disabled {
      pointer-events: none;
      color: #4f4f4f;
      &:after {
        background: #292929;
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      width: 2px;
      height: 12px;
      right: -28px;
      top: 3px;
      background: #4a4a4a;
      ${({ theme }) => theme.adaptive.index.lg} {
        right: -19px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        right: -11px;
      }
    }
    &:after {
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -3px;
      background: #3b181c;
    }
  }
`

const IndexNavigationItemActive = styled.a`
  padding: 6px 12px;
  background: rgba(155, 155, 155, 0.15);
  border: 1px solid rgba(254, 39, 59, 0.35);
  border-radius: 3px;
`

const recordAnim = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`

const IndexNavigationItemActiveCircle = styled.span`
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #fe273b;
  border-radius: 50%;
  margin-right: 15px;
  animation: ${recordAnim} 1.5s linear infinite;
`

const DocsRow = styled.div`
  position: relative;
  width: 60px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -3px;
    background: #3b181c;
  }
`

const Docs = styled.div`
  position: relative;
  width: 100%;
  font-family: 'Space Grotesk';
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  text-align: right;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 19px;
    height: 12px;
  }
  &:before {
    background: url(${folderOpenIconPath}) no-repeat center;
    background-size: contain;
    opacity: ${({ open }) => (open ? 1 : 0)};
  }
  &:after {
    background: url(${folderCloseIconPath}) no-repeat center;
    background-size: contain;
    opacity: ${({ open }) => (open ? 0 : 1)};
  }
`

const HeaderDocsWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100%);
  z-index: 2;
`
