import React from 'react'
import styled from 'styled-components'

export default function PageFooter() {
  return (
    <PageFooterRoot>
      <PageFooterAddress />
      <PageFooterCopyright>deFire © 2024</PageFooterCopyright>
    </PageFooterRoot>
  )
}

const PageFooterRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  @media screen and (max-width: 1040px) {
    flex-direction: column;
    justify-content: center;
    span {
      display: block;
      font-size: 14px;
    }
  }
`

const PageFooterAddress = styled.span`
  font-size: 16px;
  color: #706f71;
  @media screen and (max-width: 1040px) {
    margin-bottom: 5px;
  }
`

const PageFooterCopyright = styled.span`
  font-size: 16px;
  color: #fe273b;
`
