import React from 'react'
import styled from 'styled-components'
import socialsStore from '../../store/socials'

export default function Socials({ x = 'start', disabled = false }) {
  return (
    <SocialsList x={x}>
      {socialsStore.map((social, idx) => {
        return (
          <SocialLink
            key={idx}
            href={social.link}
            target="_blank"
            disabled={disabled}
          >
            {social.getIcon()}
          </SocialLink>
        )
      })}
    </SocialsList>
  )
}

const SocialsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-${({ x }) => x};
`

const SocialLink = styled.a`
  display: block;
  margin-left: 11px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    margin-left: 9px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    margin-left: 7px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    margin-left: 11px;
  }
  ${({ theme }) => theme.adaptive.newIndex.xsm} {
    margin-left: 9px;
  }
  svg {
    display: block;
    max-width: 20px;
    width: 100%;
    height: auto;
    fill: #fe273b;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.newIndex.xl} {
      max-width: 16px;
    }
    ${({ theme }) => theme.adaptive.newIndex.md} {
      max-width: 13px;
    }
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      max-width: 20px;
    }
    ${({ theme }) => theme.adaptive.newIndex.xsm} {
      max-width: 16px;
    }
  }
  &:hover {
    svg {
      fill: #fe273b;
    }
  }
  &:first-child {
    margin-left: 0;
  }
`
