import { ReactComponent as TelegramIcon } from '../img/socials/telegram.svg'
import { ReactComponent as TwitterIcon } from '../img/socials/twitter.svg'
import { ReactComponent as DiscordIcon } from '../img/socials/discord.svg'
import { ReactComponent as MediumIcon } from '../img/socials/medium.svg'

const socials = [
  {
    name: 'Telegram',
    link: 'https://t.me/defire_fi',
    getIcon: () => <TelegramIcon />,
  },
  {
    name: 'Medium',
    link: 'https://medium.com/defire',
    getIcon: () => <MediumIcon />,
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/DeFIRE_Fi',
    getIcon: () => <TwitterIcon />,
  },
  {
    name: 'Discord',
    link: 'https://discord.gg/nqPjrfy5HF',
    getIcon: () => <DiscordIcon />,
  },
  // {
  //   name: 'Instagram',
  //   link: 'https://www.instagram.com/defire.fi/',
  //   getIcon: (fill, stroke) => <InstagramIcon fill={fill} stroke={stroke} />,
  // },
  // {
  //   name: 'Reddit',
  //   link: 'https://www.reddit.com/r/deFIRE_fi/',
  //   getIcon: (fill, stroke) => <RedditIcon fill={fill} stroke={stroke} />,
  // },
]

export default socials
