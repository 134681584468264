import Color from 'color'

const red = Color('rgba(254, 39, 59)')

const palette = {
  white: '#ffffff',
  'grey-light': '#8b8b8b',
  'grey-dark': '#646464',
  red: '#fe273b',
  border: '#2c2c2c',
  'bg-light': '#212121',
  'bg-dark': '#1c1c1c',
  'red-bg': red.alpha(0.06),
  'red-border': red.alpha(0.25),
}

export default palette
