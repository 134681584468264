import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import logoPath from '../../img/logo.svg'

export default function PageHeader({ title = 'title' }) {
  return (
    <PageHeaderRoot>
      <Link to="/" className="logo-wrapper">
        <PageHeaderLogo src={logoPath} alt="DeFire logo" />
      </Link>
      <PageHeaderTitle>{title}</PageHeaderTitle>
      <PageHeaderCloseWrapper>
        <Link to="/"></Link>
      </PageHeaderCloseWrapper>
    </PageHeaderRoot>
  )
}

const PageHeaderRoot = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 45px;
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 13px 25px;
  }
  ${({ theme }) => theme.adaptive.team.sm} {
    display: block;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 13px 10px;
  }
  .logo-wrapper {
    position: relative;
    z-index: 2;
    ${({ theme }) => theme.adaptive.index.lg} {
      display: flex;
      align-items: center;
      height: 36px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      height: 33px;
    }
  }
`

const PageHeaderLogo = styled.img`
  display: block;
  width: 199px;
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 142px;
  }
  // ${({ theme }) => theme.adaptive.team.sm} {
  //   margin-bottom: 20px;
  // }
`

const PageHeaderTitle = styled.h1`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  ${({ theme }) => theme.adaptive.team.sm} {
    position: static;
    margin-top: 20px;
  }
`

const PageHeaderCloseWrapper = styled.div`
  a {
    display: block;
    width: 40px;
    height: 36px;
    position: relative;
    background: none;
    border: 0;
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 30px;
      height: 30px;
    }
    ${({ theme }) => theme.adaptive.team.sm} {
      position: absolute;
      right: 25px;
      top: 12px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #ffffff;
      left: 0;
      top: 14px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
`
