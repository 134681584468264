import { ThemeProvider } from 'styled-components'
import Router from './pages/Router'
import Fonts from './theme/fonts'
import GlobalStyles from './theme/globalStyles'
import theme from './theme/theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Fonts />
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  )
}

export default App
