import React from 'react'
import PageHeader from './PageHeader'
import styled from 'styled-components'
import PageBackgroundPath from '../../img/page-content-bg.svg'
import PageFooter from './PageFooter'

export default function PageWrapper({ children, title, color }) {
  return (
    <PageWrapperRoot>
      <PageWrap>
        <PageContentWrapper>
          <PageHeader title={title} />
          <PageChildrenWrapper>
            <PageChildrenBgWrap color={color}>
              <PageChildrenBg color={color}>{children}</PageChildrenBg>
            </PageChildrenBgWrap>
          </PageChildrenWrapper>
        </PageContentWrapper>
        <PageFooter />
      </PageWrap>
    </PageWrapperRoot>
  )
}

const PageWrapperRoot = styled.div`
  padding: 5px 5px 0 5px;
  min-height: 100%;
  position: relative;
`

const PageWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 0;
  /* background: #222222; */
`

const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #222222;
  border-radius: 5px;
  border: 1px solid #212122;
`

const PageChildrenWrapper = styled.div`
  min-height: calc(100vh - 132px);
  padding: 0 5px 5px 5px;
  ${({ theme }) => theme.adaptive.index.lg} {
    min-height: calc(100vh - 100px);
  }
  @media screen and (max-width: 1040px) {
    min-height: calc(100vh - 119px);
  }
  @media screen and (max-width: 760px) {
    min-height: calc(100vh - 110px);
  }
`

const PageChildrenBgWrap = styled.div`
  padding: 12px;
  border: ${({ color }) => (color ? '1px solid #383838' : 'none')};
  min-height: calc(100vh - 137px);
  background: ${({ color }) => (color ? color : '#171717')};
  ${({ theme }) => theme.adaptive.index.lg} {
    min-height: calc(100vh - 105px);
  }
  @media screen and (max-width: 1040px) {
    min-height: calc(100vh - 124px);
  }
  @media screen and (max-width: 760px) {
    min-height: calc(100vh - 110px);
  }
  ${({ theme }) => theme.adaptive.team.smd} {
    padding: ${({ color }) => (color ? '0' : '8px')};
  }
`

const PageChildrenBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 160px);
  height: 100%;
  background: ${({ color }) =>
    color ? color : `url(${PageBackgroundPath}) #171717`};
  background-size: 40px;
  ${({ theme }) => theme.adaptive.index.lg} {
    min-height: calc(100vh - 137px);
  }
  @media screen and (max-width: 1040px) {
    min-height: calc(100vh - 145px);
  }
  // ${({ theme }) => theme.adaptive.team.smd} {
  //   min-height: calc(100vh - 111px);
  // }
  @media screen and (max-width: 860px) {
    min-height: calc(100vh - 137px);
  }
`
