import { useEffect, useState } from 'react'
import styled from 'styled-components'
import deckDoc from '../../docs/deck.pdf'
import wpDoc from '../../docs/whitepaper-v1.pdf'
import bytes from 'bytes'
import docImagePath from '../../img/doc.png'
import downloadIconPath from '../../img/download.svg'

export default function HeaderDocs({ open = false }) {
  const [files, setFiles] = useState({})
  const [loading, setLoading] = useState(true)

  const getFileInfo = async (filePath) => {
    const fileResp = await fetch(filePath, { method: 'HEAD' })
    const fileSizeBytes = Number(fileResp.headers.get('content-length'))
    return {
      path: fileResp.url,
      size: bytes(fileSizeBytes, { decimalPlaces: 3, unitSeparator: ' ' }),
    }
  }

  useEffect(() => {
    async function handleDoc() {
      const [deck, wp] = await Promise.all([
        getFileInfo(deckDoc),
        getFileInfo(wpDoc),
      ])
      setFiles({ deck, wp })
      setLoading(false)
    }
    handleDoc()
  }, [])

  return (
    <HeaderDocsRoot active={open}>
      <HeaderDocsTitle>DeFire DOCUMENTS</HeaderDocsTitle>
      {!loading && (
        <HeaderDocsContent>
          <HeaderDocsItem href={files.deck.path} target="_blank">
            <HeaderDocsItemFile />
            <HeaderDocsItemName>DECK</HeaderDocsItemName>
            <HeaderDocsItemSize>{files.deck.size}</HeaderDocsItemSize>
          </HeaderDocsItem>
          <HeaderDocsItem href={files.wp.path} target="_blank">
            <HeaderDocsItemFile />
            <HeaderDocsItemName>Whitepaper</HeaderDocsItemName>
            <HeaderDocsItemSize>{files.wp.size}</HeaderDocsItemSize>
          </HeaderDocsItem>
        </HeaderDocsContent>
      )}
    </HeaderDocsRoot>
  )
}

const HeaderDocsRoot = styled.div`
  position: relative;
  background: rgba(28, 28, 29, 0.8);
  border: 1px solid #383838;
  padding: 13px 4px 4px 4px;
  min-width: 330px;
  border-radius: 5px;
  display: ${({ active }) => (active ? '' : 'none')};
  ${({ theme }) => theme.adaptive.index.sm} {
    min-width: auto;
    width: calc(100vw - 30px);
  }
`

const HeaderDocsTitle = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #7a7a7a;
  font-size: 16px;
  margin-bottom: 12px;
`

const HeaderDocsContent = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #323232;
  background: #202020;
  padding: 28px 0 28px 0;
`

const HeaderDocsItemName = styled.span`
  display: block;
  font-size: 14px;
  color: #fff;
  margin-bottom: 3px;
`

const HeaderDocsItemSize = styled.span`
  display: block;
  font-size: 12px;
  color: #6c6c6c;
`

const HeaderDocsItemFile = styled.div`
  position: relative;
  width: 57px;
  height: 74px;
  margin: 0 auto;
  margin-bottom: 7px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 0.2s ease;
  }
  &:before {
    background: url(${docImagePath}) no-repeat center;
    background-size: cover;
    opacity: 1;
  }
  &:after {
    background: url(${downloadIconPath}) no-repeat center;
    background-size: 25px 29px;
    opacity: 0;
  }
`

const HeaderDocsItem = styled.a`
  text-align: center;
  margin-left: 50px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    ${HeaderDocsItemFile} {
      &:after {
        opacity: 1;
      }
      &:before {
        opacity: 0.1;
      }
    }
  }
`
