import { useState, useEffect } from 'react'
import styled from 'styled-components'
import closeImg from '../../img/contact/close.svg'
import titleBg from '../../img/contact/title-bg.svg'
import Socials from '../Socials/Socials'
import contactRequest from '../../api/contactRequest'
import { noop } from 'lodash'

export default function Contact({ open = false, onContactClose = noop }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)

  useEffect(() => {
    const onEscClick = (event) => {
      if (event.keyCode === 27) {
        onContactClose()
      }
    }

    document.addEventListener('keydown', onEscClick, false)
    return () => {
      document.removeEventListener('keydown', onEscClick, false)
    }
  }, [onContactClose])

  const clearForm = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  const onFormSubmit = () => {
    setBtnDisabled(true)
    contactRequest({
      name,
      email,
      subject,
      message,
    })
      .then(() => {
        clearForm()
        setIsSuccess(true)
      })
      .catch((error) => {
        setIsSuccess(false)
        if (error.response.data) {
          error.response.data.message
            ? alert(error.response.data.message)
            : clearForm()
        } else {
          alert('Server error. Try later.')
        }
      })
      .finally(() => {
        setBtnDisabled(false)
      })
  }

  return (
    <PopupWrap active={open}>
      <PopupHeader>
        <PopupTitle>Contact us</PopupTitle>
        <PopupCloseBtn onClick={onContactClose} />
      </PopupHeader>
      <PopupCont>
        <PopupForm>
          <FormTitle />
          <InputWrapper>
            <Label>Name*</Label>
            <Input
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Email*</Label>
            <Input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Subject*</Label>
            <Input
              type="text"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </InputWrapper>
          <AreaWrapper>
            <Label>Message*</Label>
            <TextArea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </AreaWrapper>
          <BtnCont>
            {!btnDisabled ? (
              <>
                <BtnText>
                  {isSuccess ? (
                    <>Your message has been sent. Send another one?</>
                  ) : (
                    <>Send Message?</>
                  )}
                </BtnText>
                <BtnYes onClick={onFormSubmit}>Yes</BtnYes>
                <BtnNo onClick={onContactClose}>No</BtnNo>
              </>
            ) : (
              <LoadingRow>
                <LoadingText>Loading message</LoadingText>
              </LoadingRow>
            )}
          </BtnCont>
          <SocialCont>
            <SocialText>follow us</SocialText>
            <Socials stroke="#FE273B" />
          </SocialCont>
        </PopupForm>
      </PopupCont>
    </PopupWrap>
  )
}

const PopupWrap = styled.div`
  position: absolute;
  display: ${({ active }) => (active ? 'block' : 'none')};
  top: 0;
  left: 0;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 5px;
  border: 1px solid #383838;
  background: rgba(38, 38, 39, 0.8);
  border-radius: 7px;
  padding: 0 6px 0 6px;
  overflow: auto;
`

const PopupCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: calc(100% - 92px);
  border: 1px solid #383838;
  border-bottom: 0;
  /* background: #262627; */
  ${({ theme }) => theme.adaptive.contact.lg} {
    min-height: calc(100% - 53px);
    background-size: contain;
  }
`

const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  height: 86px;
  padding: 0 30px;
  ${({ theme }) => theme.adaptive.contact.lg} {
    height: 53px;
    padding: 0 16px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    justify-content: flex-end;
  }
`

const PopupTitle = styled.div`
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  ${({ theme }) => theme.adaptive.contact.lg} {
    font-size: 18px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 16px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    display: none;
  }
`

const PopupCloseBtn = styled.button`
  width: 27px;
  height: 27px;
  background: url(${closeImg}) no-repeat center;
  background-size: contain;
  ${({ theme }) => theme.adaptive.contact.lg} {
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.contact.md} {
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    width: 20px;
    height: 20px;
  }
`

const PopupForm = styled.div`
  padding: 100px 0;
  width: 920px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ theme }) => theme.adaptive.contact.lg} {
  }
  ${({ theme }) => theme.adaptive.contact.md} {
    padding: 80px 0;
    width: 580px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    padding: 40px 0;
    width: 326px;
  }
  ${({ theme }) => theme.adaptive.contact.smm} {
    padding: 20px 0;
    width: 280px;
  }
`

const FormTitle = styled.div`
  width: 100%;
  height: 89px;
  margin-bottom: 65px;
  background: url(${titleBg}) no-repeat center;
  background-size: contain;
  ${({ theme }) => theme.adaptive.contact.sm} {
    margin-bottom: 40px;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 30px;
  width: 294px;
  ${({ theme }) => theme.adaptive.contact.md} {
    width: 180px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    width: 100%;
  }
`

const AreaWrapper = styled.div`
  width: 100%;
`

const Label = styled.label`
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: #bbbbbb;
  margin-bottom: 4px;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 14px;
    line-height: 16px;
  }
`

const Input = styled.input`
  width: 100%;
  height: 47px;
  background: rgba(29, 28, 29, 0.75);
  border: 1px solid #2c2b2d;
  font-size: 16px;
  padding: 0 10px;
  color: #ffffff;
  outline: none;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 14px;
  }
`
const TextArea = styled.textarea`
  width: 100%;
  height: 197px;
  background: rgba(29, 28, 29, 0.75);
  border: 1px solid #2c2b2d;
  font-size: 16px;
  padding: 10px;
  color: #ffffff;
  outline: none;
  resize: none;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    height: 220px;
  }
`

const BtnCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
  ${({ theme }) => theme.adaptive.contact.md} {
    margin-top: 30px;
  }
  ${({ theme }) => theme.adaptive.contact.sm} {
    justify-content: center;
  }
`

const BtnYes = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: #d22837;
  margin-left: 18px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 16px;
    line-height: 18px;
  }
`

const BtnNo = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: #706f71;
  margin-left: 18px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 16px;
    line-height: 18px;
  }
`

const BtnText = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 20px;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 16px;
    line-height: 18px;
  }
`

const SocialCont = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SocialText = styled.div`
  font-size: 16px;
  color: #ffffff;
  margin-right: 25px;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.contact.md} {
    font-size: 16px;
    line-height: 18px;
  }
`

const LoadingRow = styled.div``

const LoadingText = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 20px;
  ${({ theme }) => theme.adaptive.contact.md} {
    margin-top: 30px;
    font-size: 16px;
    line-height: 18px;
  }
`
