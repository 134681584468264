import EricPhoto from '../img/people/strategy/eric.jpeg'
import AlexPhoto from '../img/people/association/alex.jpeg'
import JakiePhoto from '../img/people/association/jakie.jpeg'
import DanishPhoto from '../img/people/association/danish.jpg'
import LukaPhoto from '../img/people/devs/luka-velimirovic.jpeg'
import NikolaDavidovicPhoto from '../img/people/devs/nikola-davidovic.jpeg'

const team = [
  {
    name: 'Advisory Board',
    people: [
      {
        name: 'Alex Mascioli',
        desc: 'Advisor',
        link: 'https://www.linkedin.com/in/alexmascioli/',
        photo: AlexPhoto,
      },
      {
        name: 'Danish Chaudhry',
        desc: 'Advisor',
        link: 'https://www.linkedin.com/in/djchaudhry',
        photo: DanishPhoto,
      },
      {
        name: 'Jakie Eini',
        desc: 'Advisor',
        link: 'https://www.linkedin.com/in/jakie-eini-ab91a7152/',
        photo: JakiePhoto,
      },
    ],
  },
  {
    name: 'Strategy Board',
    people: [
      {
        name: 'Eric Benz',
        desc: 'Core Strategic Partnerships',
        link: 'https://www.linkedin.com/in/ericbenz84',
        photo: EricPhoto,
      },
    ],
  },
  // {
  //   name: 'Core Team',
  //   people: [
  //     {
  //       name: 'Luka Velimirovic',
  //       desc: 'Frontend',
  //       link: 'https://www.linkedin.com/in/luka-velimirovic-11277174/ ',
  //       photo: LukaPhoto,
  //     },
  //     {
  //       name: 'Nikola Davidovic',
  //       desc: 'Backend',
  //       link: 'https://www.linkedin.com/in/nikola-davidovi%C4%87-048259111/ ',
  //       photo: NikolaDavidovicPhoto,
  //     },
  //   ],
  // },
]

export default team
