import React from 'react'
import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import logoPath from '../../img/logo.svg'
import { contactPopupStore } from '../../store/contact'

export default function Header({ children }) {
  const isContactOpen = useStore(contactPopupStore)

  return (
    <HeaderRoot>
      <Link to="/">
        <HeaderLogo src={logoPath} alt="DeFire logo" />
      </Link>
      <HeaderRight show={!isContactOpen}>{children}</HeaderRight>
    </HeaderRoot>
  )
}

const HeaderRoot = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 45px;
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 13px 25px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 13px 10px;
  }
`

const HeaderLogo = styled.img`
  display: block;
  width: 199px;
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 142px;
  }
`

const HeaderRight = styled.div`
  position: relative;
  opacity: ${({ show }) => (show ? 1 : 0)};
`
