import React from 'react'
import styled from 'styled-components'
import occamPath from '../../img/occam.svg'

export default function IndexTitle({ show = true }) {
  return (
    <IndexTitleRoot show={show}>
      <IndexTitleText>Hub for Optimal Onchain Execution</IndexTitleText>
      {/*<IncubatedByWrap>*/}
      {/*  <IncubatedBy>Incubated by</IncubatedBy>*/}
      {/*  <IncubatedByImage href={'https://occam.fi/'} tarhet={'_blank'} />*/}
      {/*</IncubatedByWrap>*/}
    </IndexTitleRoot>
  )
}

const IndexTitleRoot = styled.div`
  max-width: 645px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    max-width: 510px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    max-width: 450px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    max-width: 415px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px 0 26px;
  }
  ${({ theme }) => theme.adaptive.newIndex.xsm} {
    max-width: 263px;
    padding: 9px 0 13px;
  }
`

const IndexTitleText = styled.h1`
  font-family: 'Helvetica Neue';
  font-weight: 200;
  font-size: 78px;
  line-height: 94px;
  letter-spacing: 2px;
  color: #ffffff;
  margin-bottom: 37px;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    font-size: 62px;
    line-height: 74px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    font-size: 54px;
    line-height: 65px;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    font-size: 41px;
    line-height: 49px;
    padding: 0;
    margin: 0;
  }
  ${({ theme }) => theme.adaptive.newIndex.xsm} {
    font-size: 30px;
    line-height: 36px;
    padding: 0;
  }
`

const IncubatedBy = styled.div`
  font-family: 'Space Grotesk';
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #777777;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    font-size: 15px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    font-size: 13px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    font-size: 16px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.newIndex.xsm} {
    font-size: 13px;
    line-height: 17px;
  }
`

const IncubatedByImage = styled.a`
  display: block;
  width: 180px;
  height: 27px;
  margin-top: 10px;
  background: url('${occamPath}') center no-repeat;
  background-size: cover;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    width: 145px;
    height: 22px;
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    width: 123px;
    height: 18px;
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 165px;
    height: 25px;
    margin-top: 9px;
  }
  ${({ theme }) => theme.adaptive.newIndex.xsm} {
    width: 135px;
    height: 20px;
    margin-top: 8px;
  }
`

const IncubatedByWrap = styled.div``
